import React, { useState } from 'react';
import HomeHeader from 'views/home/components/HomeHeader';
import useSendRequest from 'common/hooks/useSendRequest';
import { API } from 'config/axios';
import moment from 'moment';
import { Router } from 'config/i18n';
import { useLayoutLoadingSpinner } from 'components/shared/Layout';

const HomeHeaderWrapper = ({ i18n }) => {
  const [, setLoading] = useLayoutLoadingSpinner();
  const [pickupLocations, setPickupLocations] = useState([]);
  const [selectedPickupPlace, setSelectedPickupPlace] = useState(null);
  const [placesLoading, setPlacesLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  const fetchPickupLocations = useSendRequest({
    url: API.rentals.pickupPlaces,
    method: 'GET',
    loader: false,
    afterSuccessCallback: (res) => {
      setPickupLocations(res.data.pickup_places);
      setPlacesLoading(false);
    },
    afterErrorCallback: () => {
      setPickupLocations([]);
      setPlacesLoading(false);
    },
  });

  const onPickupInputChange = (value) => {
    if (value.length > 2) {
      setPlacesLoading(true);
      setPickupLocations([]);
      fetchPickupLocations.sendRequest({}, { q: value });
    }
  };

  const onDateChange = (value) => {
    setSelectedDate(moment(value).format('YYYY-MM-DD'));
  };

  const onSelectCurrentLocation = (value) => {
    setLoading(true);
    setSelectedPickupPlace({
      id: null,
      name: i18n('fe_geo_widget:place.current_location'),
      location: { lat: value.lat, lng: value.lng },
      search_radius: 50, // This is a default value
    });
    setUserCurrentLocation(true);
    setLoading(false);
  };

  const generateSearchLink = () => {
    // const prefix = `${window.location.origin}/rentals`;
    const rentalSlug = i18n('routes:motorcycle-rental') || 'motorcycle-rental';
    let url = `/${rentalSlug}`;
    setLoading(true);
    if (selectedPickupPlace && userCurrentLocation && !selectedDate) {
      url += `?lat=${selectedPickupPlace.location.lat}&lng=${selectedPickupPlace.location.lng}`;
    } else if (selectedPickupPlace && !selectedDate) {
      url += `/${selectedPickupPlace.slug}`;
    } else if (selectedPickupPlace && userCurrentLocation && selectedDate && moment(selectedDate).isValid()) {
      const dropoffDate = moment((moment(selectedDate).add(1, 'd'))).format('YYYY-MM-DD');
      // eslint-disable-next-line max-len
      url += `?lat=${selectedPickupPlace.location.lat}&lng=${selectedPickupPlace.location.lng}&pickup_date=${selectedDate}&dropoff_date=${dropoffDate}`;
    } else if (selectedPickupPlace && selectedDate && moment(selectedDate).isValid()) {
      const dropoffDate = moment((moment(selectedDate).add(1, 'd'))).format('YYYY-MM-DD');
      url += `/${selectedPickupPlace.slug}?pickup_date=${selectedDate}&dropoff_date=${dropoffDate}`;
    } else {
      setLoading(false);
    }
    Router.push(url);
  };

  const widgetProps = {
    place: {
      pickupLocations,
      selectedPickupPlace,
      onChange: (value) => setSelectedPickupPlace(value),
      onInputChange: (value) => onPickupInputChange(value),
      loading: placesLoading,
      getCurrentLocation: (value) => onSelectCurrentLocation(value),
    },
    time: {
      selectedDate,
      onChange: (value) => onDateChange(value),
      minimumDate: new Date(),
    },
    search: generateSearchLink,
  };

  return (
    <>
      <HomeHeader
        i18n={i18n}
        widgetProps={widgetProps}
      />
    </>
  );
};
export default HomeHeaderWrapper;
