import React from 'react';
import {
  Box, makeStyles, Typography, Button, Hidden,
} from '@material-ui/core';
import useSiteSlug from 'common/hooks/useSiteSlug';
import { useLayoutState } from 'components/shared/Layout';

const useStyles = makeStyles((theme) => ({
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4.5),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: '500',
    color: theme.palette.grey[700],
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  mLink: {
    marginTop: theme.spacing(2),
  },
}));

const FeaturedSection = ({ title, link, children }) => {
  const classes = useStyles();
  const { structureURL } = useSiteSlug();
  const { layoutState } = useLayoutState();
  return (
    <Box>
      <Box className={classes.headerBox}>
        <Typography variant="h2" className={classes.title}>{title}</Typography>

        {(link && layoutState && layoutState.current_language) && (
        <Hidden smDown>
          <Button
            href={structureURL(link.url, layoutState.current_language.code)}
            className={classes.link}
            color="secondary"
            variant="outlined"
          >
            {link.text}
          </Button>
        </Hidden>
        )}

      </Box>
      {children}

      {(link && layoutState && layoutState.current_language) && (
      <Hidden mdUp>
        <Box textAlign="center">
          <Button
            href={structureURL(link.url, layoutState.current_language.code)}
            className={classes.mLink}
            color="secondary"
            variant="outlined"
          >
            {link.text}
          </Button>
        </Box>
      </Hidden>
      )}

    </Box>
  );
};
export default FeaturedSection;
