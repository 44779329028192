import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Container } from '@material-ui/core';
import HowRentingWorks from 'components/ListYourBike/HowRentingWorks';
// import InsuranceInfo from 'components/ListYourBike/InsuranceInfo';
import Advertise from 'common/components/Advertise';
import FeaturedSection from 'views/home/components/FeaturedSection';
import BikesCardsSlider from 'views/home/components/BikesCardsSlider';
import FeaturedBikeDestinations from 'views/home/components/FeaturedBikeDestinations';
import IntroText from 'views/home/components/IntroText';
import { Router } from 'config/i18n';
import LazyLoad from 'common/components/LazyLoad';
import adImage from '../../public/imgs/home/ad.webp';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(15),
    },
  },
  adContainer: {
    paddingBottom: theme.spacing(0),
    marginTop: theme.spacing(0),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(15),
      marginTop: theme.spacing(1.25),
    },
  },
}));

const HomeView = ({
  i18n, data, ssrRender, deviceType,
}) => {
  const classes = useStyles();

  const generateCardsArr = () => (data.featured_vehicles ? data.featured_vehicles.map((vehicle) => ({
    id: vehicle.id,
    region: {
      name: `${vehicle.location.geo.name}, ${vehicle.location.region}`,
    },
    vehicle_make: vehicle.make,
    vehicle_model: vehicle.model,
    image_path: vehicle.image_url,
    price: vehicle.daily_rate,
    hourly_price: vehicle.hourly_rate,
    url: `/${vehicle.make.slug}/${vehicle.model.slug}/${vehicle.location.geo.slug}/${vehicle.id}`,
    owner: vehicle.owner,
  })) : []);

  const onBikeCardClick = (url) => {
    Router.push(url);
  };

  // To fix a scrolling issue for slider
  const [isMoving, setIsMoving] = useState(false);
  let firstClientX;
  let firstClientY;
  let clientX;
  // eslint-disable-next-line no-unused-vars
  let clientY;
  const touchStart = (e) => {
    firstClientX = e.touches[0].clientX;
    firstClientY = e.touches[0].clientY;
  };
  const preventTouch = (e) => {
    const minValue = 5; // threshold
    clientX = e.touches[0].clientX - firstClientX;
    clientY = e.touches[0].clientY - firstClientY;
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (process.browser) {
      if (isMoving) {
        window.removeEventListener('touchstart', touchStart);
        window.removeEventListener('touchmove', preventTouch, { passive: false });
      } else {
        window.addEventListener('touchstart', touchStart);
        window.addEventListener('touchmove', preventTouch, { passive: false });
      }
    }
  }, [isMoving]);

  return (
    <>
      {data && data.featured_vehicles && data.featured_vehicles.length > 0 && (
        <LazyLoad ssrRender={ssrRender}>
          <Container className={classes.container}>
            <FeaturedSection
              title={i18n('fe_featured_home_section:listed_motorcycles')}
              link={{
                text: i18n('fe_featured_home_section:explore_all'),
                url: 'motorcycle-rental',
              }}
            >
              <BikesCardsSlider
                cardsArr={generateCardsArr()}
                setIsMoving={setIsMoving}
                onBikeCardClick={onBikeCardClick}
                deviceType={deviceType}
                ssrRender={ssrRender}
              />
            </FeaturedSection>
          </Container>
        </LazyLoad>
      )}
      {data && data.featured_destinations.length > 0 && (
        <LazyLoad ssrRender={ssrRender}>
          <Container className={classes.container}>
            <FeaturedSection
              title={i18n('fe_featured_home_section:listed_destinations')}
              link={{
                text: i18n('fe_featured_home_section:explore_all'),
                url: 'motorcycle-rental',
              }}
            >
              <FeaturedBikeDestinations
                destinationsArr={data.featured_destinations}
                setIsMoving={setIsMoving}
                deviceType={deviceType}
                ssrRender={ssrRender}
              />
            </FeaturedSection>
          </Container>
        </LazyLoad>
      )}
      {/* <LazyLoad ssrRender={ssrRender}>
        <Box className={classes.container}>
          <InsuranceInfo i18n={i18n} />
        </Box>
      </LazyLoad> */}
      <LazyLoad ssrRender={ssrRender}>
        <Box className={classes.container}>
          <HowRentingWorks i18n={i18n} />
        </Box>
      </LazyLoad>
      <LazyLoad ssrRender={ssrRender}>
        <Box className={`${classes.container} ${classes.adContainer}`}>
          <Advertise
            title={i18n('fe_ad_home_page:title')}
            info={i18n('fe_ad_home_page:info')}
            button={{
              text: i18n('fe_ad_home_page:get_started'),
              url: {
                pathName: '/rent-my-motorcycle',
                as: `/${i18n('routes:rent-my-motorcycle')}`,
              },
            }}
            imgUrl={adImage}
          />
        </Box>
      </LazyLoad>
      <LazyLoad ssrRender={ssrRender}>
        <IntroText i18n={i18n} />
      </LazyLoad>
    </>
  );
};
export default HomeView;
