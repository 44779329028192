import React from 'react';
import {
  makeStyles, Box, Typography, useTheme, useMediaQuery,
} from '@material-ui/core';
import HomeWidget from 'views/home/components/HomeWidget';
import Image from 'next/image';
import smHeader from '../../../public/imgs/home/es-home-cover-sm.webp';
import mdHeader from '../../../public/imgs/home/es-home-cover-md.webp';
import lgHeader from '../../../public/imgs/home/es-home-cover-lg.webp';

const useStyles = makeStyles((theme) => ({
  headerBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    minHeight: theme.spacing(65),
    [theme.breakpoints.up('md')]: {
      minHeight: theme.spacing(63.75),
    },
    [theme.breakpoints.up('xl')]: {
      minHeight: theme.spacing(75),
    },
  },
  contentBox: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(9.25),
  },
  title: {
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(28),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(36),
    },
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(20),
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
}));

const HomeHeader = ({ i18n, widgetProps }) => {
  const classes = useStyles();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box className={classes.headerBox}>
      <Image
        // eslint-disable-next-line no-nested-ternary
        src={smallScreen ? smHeader
          : (mediumScreen ? mdHeader
            : lgHeader)}
        alt="eagleShare"
        placeholder="blur"
        layout="fill"
        objectFit="cover"
        objectPosition="50% 50%"
        unoptimized
        priority
      />
      <Box className={classes.contentBox}>
        <Typography variant="h1" component="h1" className={classes.title}>
          {i18n('fe_home_header:rent_motorcycle_wherever_you_are')}
        </Typography>
        <Typography className={classes.subtitle}>
          {i18n('fe_home_header:ride_with_es')}
        </Typography>
        <HomeWidget
          widgetProps={widgetProps}
          i18n={i18n}
        />
      </Box>
    </Box>
  );
};
export default HomeHeader;
