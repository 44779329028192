import React from 'react';
import {
  makeStyles, Typography, Grid, Box,
} from '@material-ui/core';
import Image from 'next/image';
import introTxt from '../../../public/imgs/home/introTxt.webp';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: theme.palette.secondary.dark,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
  },
  imgGrid: {
    minHeight: theme.spacing(130),
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      zIndex: '0',
      opacity: '0.1',
    },
  },
  txtGrid: {
    color: theme.palette.common.white,
    padding: theme.spacing(4, 4, 2, 4),
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      zIndex: '1',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 6.5, 1, 6.5),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(92.5),
      padding: theme.spacing(4, 4, 1, 8),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 0, 1, 10),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  details: {
    color: theme.palette.grey[100],
    marginBottom: theme.spacing(3),
  },
}));

const IntroText = ({ i18n }) => {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.mainContainer }}>
      <Grid
        container
        justifycontent="space-between"
        alignItems="stretch"
      >
        <Grid item xs={12} lg={5} className={classes.imgGrid}>
          <Image
            src={introTxt}
            alt="eagleShare"
            placeholder="blur"
            layout="fill"
            objectFit="cover"
            objectPosition="50% top"
            unoptimized
          />
        </Grid>
        <Grid item xs={12} lg={7} classes={{ item: classes.txtGrid }}>
          <Typography variant="h2" classes={{ root: classes.title }}>
            EagleShare
          </Typography>
          <Typography classes={{ root: classes.details }}>
            {i18n('fe_es_intro_text:intro')}
          </Typography>

          <Typography variant="h2" classes={{ root: classes.title }}>
            {i18n('fe_es_intro_text:why_rent_es')}
          </Typography>
          <Typography
            classes={{ root: classes.details }}
            component="div"
            dangerouslySetInnerHTML={{
              __html: i18n('fe_es_intro_text:why_rent_es_info'),
            }}
          />

          <Typography variant="h2" classes={{ root: classes.title }}>
            {i18n('fe_es_intro_text:why_list_es')}
          </Typography>
          <Typography
            classes={{ root: classes.details }}
            component="div"
            dangerouslySetInnerHTML={{
              __html: i18n('fe_es_intro_text:why_list_es_info'),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default IntroText;
