import React, { useState } from 'react';
import {
  makeStyles, Box, Tooltip, useMediaQuery, useTheme, Grid,
} from '@material-ui/core';
import TooltipStyle from 'views/motorcycle_rental/components/widget/TooltipStyle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EventIcon from '@material-ui/icons/Event';
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  ...TooltipStyle(theme),
  tipBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(2),
    cursor: 'not-allowed',
    minWidth: theme.spacing(32.5),
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 4,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      height: theme.spacing(7),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(1.375, 1.125),
    },
  },
  tipCalendar: {
    fill: theme.palette.grey[200],
    // width: theme.spacing(2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.125),
      marginTop: theme.spacing(0.75),
      marginLeft: theme.spacing(0.25),
    },
  },
  tipLabel: {
    color: theme.palette.grey[200],
    display: 'block',
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    marginBottom: theme.spacing(0.25),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0.75),
    },
  },
  tipText: {
    color: theme.palette.grey[200],
    fontWeight: '300',
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.typography.pxToRem(1),
    },
  },
  pickerBox: {
    "& [class*='MuiOutlinedInput-adornedStart']": {
      paddingLeft: 0,
      backgroundColor: theme.palette.common.white,
    },
    '& svg': {
      // color: theme.palette.primary.main,
      [theme.breakpoints.up('md')]: {
        // width: theme.spacing(2),
        // height: theme.spacing(2),
      },

    },
    '& .Mui-focused svg': {
      fill: theme.palette.primary.main,
      color: theme.palette.primary.main,

    },
    "& [class*='MuiInput-root']": {
      '&::before, &::after': {
        borderBottom: 0,
        content: 'none',
      },
    },
    '& input': {
      '&::placeholder': {
        color: theme.palette.secondary.dark,
        fontWeight: '300',
        opacity: '0.8',
      },
    },
    '& .MuiInputAdornment-positionStart': {
      marginTop: theme.spacing(-3.5),
      marginRight: theme.spacing(0.5),
      [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(-0.125),
        marginTop: 0,
      },
    },
    '& .MuiFormLabel-root': {
      left: theme.spacing(6.5),
      color: theme.palette.grey[700],
      [theme.breakpoints.down('sm')]: {
        left: 0,
      },
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiFormHelperText-root': {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(-1.125),
      },
    },
  },

}));

const HomeWidgetDatePicker = ({
  i18n, data,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediumUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    tooltip, selectedDate, minimumDate, disabled, onChange,
  } = data;

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  return (
    <>
      {tooltip ? (
        <Tooltip
          arrow
          classes={{
            arrow: classes.tooltipArrowOverride,
            tooltip: classes.tooltipOverride,
          }}
          enterTouchDelay={10}
          title={(
            <>
              <ErrorOutlineIcon />
              {' '}
              {i18n('fe_geo_widget:time.enter_place_first')}
            </>
                )}
        >
          <Box className={classes.tipBox}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <EventIcon className={classes.tipCalendar} />
              </Grid>
              <Grid item>
                {mediumUp
                && <span className={classes.tipLabel}>{i18n('fe_geo_widget:time.when')}</span>}
                <span className={classes.tipText}>{i18n('fe_geo_widget:time.label')}</span>
              </Grid>
            </Grid>
          </Box>
        </Tooltip>
      ) : (
        <Box className={classes.pickerBox}>
          <KeyboardDatePicker
            fullWidth
            variant="inline"
            margin="normal"
            label={i18n('fe_geo_widget:time.when')}
            format="MM/DD/yyyy"
            value={selectedDate}
            onChange={(date) => onChange(date)}
            animateYearScrolling
            autoOk
            disableToolbar
            minDate={minimumDate}
            placeholder={i18n('fe_geo_widget:time.label')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputAdornmentProps={{ position: 'start' }}
            onClick={() => setIsDatePickerOpen(true)}
            onClose={() => setIsDatePickerOpen(false)}
            disabled={disabled}
            open={isDatePickerOpen}
            inputVariant={mediumUp
              ? 'standard'
              : 'outlined'}
          />
        </Box>
      )}
    </>
  );
};
export default HomeWidgetDatePicker;
