import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  useTheme, useMediaQuery,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import Image from 'next/image';
import getERUrl from 'utils/getERUrl';
import rentingWorksSm from '../../public/imgs/list-your-bike/renting_works_sm.webp';
import rentingWorksLg from '../../public/imgs/list-your-bike/renting_works_lg.webp';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(22),
    marginBottom: theme.spacing(0),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(36),
      marginBottom: theme.spacing(2),
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  subTitle: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(18),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(32),
      marginBottom: theme.spacing(6),
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
    },
  },
  textContainer: {
    borderLeft: `1px dashed ${theme.palette.grey[200]}`,
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(1.5),
    },
  },
  stepContainer: {
    marginLeft: theme.spacing(-2.5),
    width: '85%',
    padding: theme.spacing(4.5, 0, 2),
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(-1.5),
      width: 'auto',
    },
  },
  stepNumber: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.primary.main,
    borderRadius: 10,
    boxShadow: `0 0 4px 0 ${alpha(theme.palette.common.black, 0.15)}`,
    border: `3px solid ${theme.palette.primary.main}`,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 'bold',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.only('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: 3,
    },
  },
  stepTitle: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(24),
    fontWeight: '500',
    lineHeight: theme.typography.pxToRem(40),
    [theme.breakpoints.only('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  stepDesc: {
    marginLeft: theme.spacing(7),
    letterSpacing: theme.typography.pxToRem(0.5),
    lineHeight: theme.typography.pxToRem(24),
    marginTop: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.only('sm')]: {
      marginLeft: theme.spacing(5),
    },
  },
  imgContainer: {
    height: theme.spacing(42.5),
    marginTop: theme.spacing(5.5),
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 20,
    [theme.breakpoints.up('lg')]: {
      height: '96%',
      marginTop: theme.spacing(4.5),
    },
    [theme.breakpoints.only('sm')]: {
      height: theme.spacing(20),
      marginTop: theme.spacing(2.75),
      borderRadius: 15,
    },
  },
}));

const HowRentingWorks = ({ i18n }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Container>

        <Typography variant="h2" classes={{ root: classes.title }}>
          {i18n('fe_es_how_renting_works:title')}
        </Typography>
        <Typography variant="h3" classes={{ root: classes.subTitle }}>
          {i18n('fe_es_how_renting_works:sub_title')}
        </Typography>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={12} lg={7}>
            <Box className={classes.textContainer}>
              <Box className={classes.stepContainer}>
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Box className={classes.stepNumber}>1</Box>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h3"
                      classes={{ root: classes.stepTitle }}
                    >
                      {i18n('fe_es_how_renting_works:steps.title1')}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography classes={{ root: classes.stepDesc }}>
                  {i18n('fe_es_how_renting_works:steps.desc1')}
                </Typography>

              </Box>

              <Box className={classes.stepContainer}>
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Box className={classes.stepNumber}>2</Box>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h3"
                      classes={{ root: classes.stepTitle }}
                    >
                      {i18n('fe_es_how_renting_works:steps.title2')}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography classes={{ root: classes.stepDesc }}>
                  {i18n('fe_es_how_renting_works:steps.desc2')}
                </Typography>

              </Box>

              <Box className={classes.stepContainer}>
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Box className={classes.stepNumber}>3</Box>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h3"
                      classes={{ root: classes.stepTitle }}
                    >
                      {i18n('fe_es_how_renting_works:steps.title3')}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography classes={{ root: classes.stepDesc }}>
                  {i18n('fe_es_how_renting_works:steps.desc3')}
                </Typography>
              </Box>

              <Box className={classes.stepContainer}>
                <Grid
                  container
                  wrap="nowrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <Box className={classes.stepNumber}>4</Box>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h3"
                      classes={{ root: classes.stepTitle }}
                    >
                      {i18n('fe_es_how_renting_works:steps.title4')}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography classes={{ root: classes.stepDesc }}>
                  {i18n('fe_es_how_renting_works:steps.desc4')}
                </Typography>

                <Typography
                  classes={{ root: classes.stepDesc }}
                  dangerouslySetInnerHTML={{
                    __html: i18n('fe_es_how_renting_works:steps.desc4_2', {
                      club_path: getERUrl(`/${i18n('routes:motorcycle-subscription')}`, i18n.language),
                      commercial_host_path: 'https://www.eagleshare.com/commercial-host',
                    }),
                  }}
                />
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} lg={5}>
            <Box className={classes.imgContainer}>
              <Image
                // className={classes.img}
                src={smallScreen ? rentingWorksSm : rentingWorksLg}
                alt={i18n('fe_es_how_renting_works:title')}
                layout="fill"
                objectFit="cover"
                objectPosition="50% 50%"
                placeholder="blur"
                unoptimized
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HowRentingWorks;
