// SERVER SIDE SEARCH
import React, { useState, useEffect } from 'react';
import {
  makeStyles, TextField, Paper, InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlaceRenderedOption from 'views/motorcycle_rental/components/widget/PlaceRenderedOption';
import CurrentLocationButton from 'views/motorcycle_rental/components/widget/CurrentLocationButton';
import { withTranslation } from 'config/i18n';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  autocompleteRoot: {
    minWidth: '46% !important',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiInputBase-root': {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(3),
      },
    },
    '& .MuiInput-underline': {
      '&::after, &::before': {
        content: 'none',
      },
    },
    '& .MuiInputLabel-formControl': {
      left: theme.spacing(6.5),
      [theme.breakpoints.only('md')]: {
        left: theme.spacing(7.5),
      },
      [theme.breakpoints.down('sm')]: {
        top: 0,
        left: 0,
      },
    },
  },
  pickupAutocomplete: {
    flex: (props) => (props.homeWidget ? '.5' : 1),
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      minWidth: (props) => {
        if (props.noCheckBoxNorDropoff) {
          return theme.spacing(30);
        }
        if (props.checkBoxShown) {
          return theme.spacing(50);
        }
        return theme.spacing(20);
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(7), // needed to check for geo page
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: (props) => (props.checkBoxShown ? theme.spacing(50) : theme.spacing(30)),
    },
  },
  textFieldRootOverride: {
    height: '100%',
    '& input': {
      // fontSize: theme.typography.pxToRem(16),
      '&::placeholder': {
        // fontSize: theme.typography.pxToRem(14),
        color: theme.palette.secondary.dark,
        fontWeight: '300',
        paddingTop: theme.spacing(0.5),
        opacity: '0.8',
      },
    },
    '& .MuiAutocomplete-input': {
      paddingTop: `${theme.spacing(1.25)} !important`,
    },
    // can't add a class to this
    '& .MuiFormLabel-root': {
      top: theme.spacing(2),
      color: theme.palette.grey[700],
      [theme.breakpoints.down('md')]: {
        left: theme.spacing(6.5),
      },
      [theme.breakpoints.up('md')]: {
        left: theme.spacing(7.5),
      },
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(0),
        left: theme.spacing(0),
      },
      '.Mui-focused &': {
        color: theme.palette.primary.main,
      },
    },
  },
  inputBaseRootOverride: {
    height: '100%',
    // to give weight to the selector
    '& .MuiInputBase-root': {
      [theme.breakpoints.up('md')]: {
        paddingRight: (props) => (props.checkBoxShown ? theme.spacing(25) : 0),
      },
    },
    '&::before': {
      borderBottom: (props) => (props.selectedPickupPlace ? '0' : '2px solid'),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      fontSize: theme.typography.pxToRem(16),
    },
  },
  adornmentRoot: {
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-end',
    },
  },
  adornmentIcon: {
    // width: theme.spacing(2),
    // height: theme.spacing(2),
    fill: theme.palette.grey[300],
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    '.Mui-focused &': {
      fill: theme.palette.primary.main,
    },
  },
  popperPaper: {
    minWidth: theme.spacing(43),
    maxWidth: '90vw',
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(45),
    },
  },
}));

const SearchPickup = ({ t, data, readOnly }) => {
  const {
    pickupLocations,
    selectedPickupPlace,
    onChange,
    label,
    getCurrentLocation,
    checkBoxShown,
    noCheckBoxNorDropoff,
    variant,
    popperPaperStyle = { width: 420 },
    ShowCurrentLocationButton,
    onInputChange,
    loading,
    disableClearable,
    homeWidget,
  } = data;

  const classes = useStyles({
    checkBoxShown,
    selectedPickupPlace,
    noCheckBoxNorDropoff,
    homeWidget,
  });
  const [inputValue, setInputValue] = useState('');
  const [focused, setFocused] = useState(false);

  // to save the original value coming from the props
  // const [originalValue, setOriginalValue] = useState(selectedPickupPlace);

  // check if there is a value to add to the array if not create empty array
  const getOptions = () => {
    if (pickupLocations.length) {
      return [...pickupLocations];
    }
    if (selectedPickupPlace) {
      return [selectedPickupPlace];
    }
    return [];
  };

  const [options, setOptions] = useState(getOptions());

  useEffect(() => {
    setOptions(pickupLocations);
  }, [pickupLocations]);

  return (
    <>
      <Autocomplete
        freeSolo
        autoHighlight
        className={classes.pickupAutocomplete}
        classes={{ root: classes.autocompleteRoot }}
        id="pickup"
        options={options}
        // adding props "optionLabel" in case we have an objects on array to select which variable we should work on
        getOptionLabel={(option) => option.name}
        // to render custom options
        renderOption={(option) => <PlaceRenderedOption option={option} />}
        getOptionSelected={(option, value) => option.name == value.name}
        value={focused && !readOnly ? null : selectedPickupPlace || null}
        onChange={(e, value) => {
          onChange(value);
        }}
        inputValue={inputValue}
        onInputChange={(e, value, reason) => {
          setInputValue(value);
          onInputChange(value, reason);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={focused || selectedPickupPlace || variant == 'standard' ? label : null}
            // label={label}
            placeholder={t('fe_es_geo_widget:place.pickup_label')}
            variant={variant}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            classes={{
              root: classes.textFieldRootOverride,
            }}
            InputProps={{
              ...params.InputProps,
              readOnly,
              classes: {
                root: classes.inputBaseRootOverride,
              },
              startAdornment: (
                <InputAdornment position="start" className={classes.adornmentRoot}>
                  <LocationOnIcon className={`${classes.adornmentIcon} locationIconOverride`} />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        disableClearable={disableClearable}
        PaperComponent={({ children, ...other }) => (
          <Paper
            {...other}
            className={classes.popperPaper}
            style={{
              ...popperPaperStyle,
            }}
          >
            {ShowCurrentLocationButton && (
              <CurrentLocationButton
                onClick={(currentLocation) => getCurrentLocation(currentLocation)}
              />
            )}
            {options.length !== 0 && children}
          </Paper>
        )}
        filterOptions={(optionsArr) => optionsArr}
      />
    </>
  );
};
export default withTranslation(['fe_es_geo_widget'])(SearchPickup);
