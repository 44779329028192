const TooltipStyle = (theme) => ({
  tooltipArrowOverride: {
    color: theme.palette.common.black,
  },
  tooltipOverride: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    opacity: '1',
    width: theme.spacing(31.25),
    '& svg': {
      width: theme.spacing(1.625),
      height: theme.spacing(1.625),
      margin: theme.spacing(0.5, 0.5, 0, 0),
    },
  },
  tipWrap: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '50%',
  },
});

export default TooltipStyle;
