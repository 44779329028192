import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import {
  Box, makeStyles, Typography, useTheme, useMediaQuery,
} from '@material-ui/core';
import Link from 'components/shared/Link';
import Image from 'next/image';
import blur from '../../public/imgs/1x1.png';

const useStyles = makeStyles((theme) => ({
  destinationCard: {
    position: 'relative',
  },
  destinationImgBox: {
    overflow: 'hidden',
    // height: theme.spacing(30),
    borderRadius: 15,
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      // height: theme.spacing(51.375),
      marginBottom: theme.spacing(2),
    },
  },
  destinationImg: {
    height: '100%',
    minWidth: '100%',
    width: 'auto',
  },
  destinationTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '500',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(22),
    },
  },
  destinationLink: {
    width: '100%',
    height: '100%',
    zIndex: '2',
    position: 'absolute',
    top: '0',
    left: '0',
  },
}));

const DestinationCard = ({ destination }) => {
  const classes = useStyles();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box className={classes.destinationCard}>
        <Box className={classes.destinationImgBox}>
          <img
            src={smallScreen ? destination.image_url.small : destination.image_url.medium}
            alt={destination.title}
            className={classes.destinationImg}
          />
          <Image
            // eslint-disable-next-line no-nested-ternary
            className={classes.destinationImg}
            src={smallScreen ? destination.image_url.small : destination.image_url.medium}
            alt={destination.title}
            placeholder="blur"
            blurDataURL={blur.src}
            layout="fill"
            objectFit="cover"
            unoptimized
          />
        </Box>
        <Typography
          variant="h3"
          className={classes.destinationTitle}
        >
          {destination.title}
        </Typography>
        <Link
          className={classes.destinationLink}
          href={destination.url}
        >
          <span />
        </Link>
      </Box>
    </>
  );
};
export default DestinationCard;
