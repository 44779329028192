// Will have if needed getInitialProps
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'config/i18n';
import { serverFetch } from 'utils/serverFetch';
import axios, { API } from 'config/axios';
import Error from 'components/shared/Error';
import Head from 'next/head';
import generateMetaData from 'utils/generateMetaData';
import generateHreflang from 'utils/generateHreflang';
import isCrawlers from 'common/utils/isCrawlers';
import MobileDetect from 'mobile-detect';

import useHandleResponse from 'utils/useHandleResponse';
import HomeHeaderWrapper from 'views/home/components/HomeHeaderWrapper';
import logger from 'loglevel';
import HomeView from 'views/home/index';

const Home = ({
  t, serverData, error, errorStatusCode, headLinks, ssrRender, deviceType,
}) => {
  if (error) {
    return <Error statusCode={errorStatusCode || 500} />;
  }

  const { handleError } = useHandleResponse();

  const [data, setData] = useState(serverData);
  useEffect(() => {
    if (process.browser && !ssrRender && !serverData) {
      logger.debug('[debug]>>>home page');
      axios
        .get(API.homepage)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, []);

  useEffect(() => {
    window.ga('send', 'event', 'Homepage', 'Pageview');
  }, []);

  return (
    <>
      <Head>

        {data && data.meta_data && generateMetaData(data.meta_data)}
        {!process.browser && (
        <>
          <link rel="canonical" href={headLinks.canonical} />
          {headLinks.urls.map((local) => (
            <link
              key={local.hreflang}
              rel="alternate"
              href={local.url}
              hrefLang={local.hreflang}
            />
          ))}
        </>
        )}
      
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data?.structured_data) }}
        />

      </Head>
      <HomeHeaderWrapper
        i18n={t}
      />
      <HomeView ssrRender={ssrRender} i18n={t} data={data} deviceType={deviceType} />
    </>
  );
};

Home.getInitialProps = async (ctx) => {
  const { res, req } = ctx;

  const ssrRender = req ? isCrawlers(req.headers['user-agent']) : false;
  let userAgent;
  let deviceType;
  let serverData = null;
  let error;
  // generate canonical and hrefLang
  const host = ctx.req ? ctx.req.headers.host : window.location.host;
  let url;
  ctx.req ? (url = ctx.req.url) : (url = ctx.asPath);
  const headLinks = generateHreflang(url, '/', host, true);

  if (ssrRender) {
    const {
      success, data, errorRes,
    } = await serverFetch(
      ctx,
      API.homepage,
    );
    if (success && data) {
      serverData = data;
      error = errorRes;
    }
  }
  if (req) {
    userAgent = req.headers['user-agent'];
  } else {
    userAgent = navigator.userAgent;
  }
  const md = new MobileDetect(userAgent);
  if (md.tablet()) {
    deviceType = 'tablet';
  } else if (md.mobile()) {
    deviceType = 'mobile';
  } else {
    deviceType = 'desktop';
  }
  return {
    namespacesRequired: [
      'fe_es_how_renting_works',
      'fe_es_insurance_info_component',
      'fe_list_your_bike',
      'fe_ad_home_page',
      'fe_featured_home_section',
      'fe_bike_card',
      'fe_home_header',
      'fe_geo_widget',
      'fe_es_intro_text',
    ],
    serverData,
    ssrRender,
    pendingOnLayout: false,
    headLinks,
    error,
    errorStatusCode: error && error.response && error.response.status, // as error.response is not passed to the browser
    deviceType,
  };
};

export default withTranslation([
  'fe_es_how_renting_works',
  'fe_es_insurance_info_component',
  'fe_list_your_bike',
  'fe_ad_home_page',
  'fe_es_intro_text',
  'fe_featured_home_section',
  'fe_bike_card',
  'fe_home_header',
  'fe_geo_widget',
])(Home);
