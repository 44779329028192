import { useState } from 'react';
import axios from 'config/axios';
import {
  useLayoutLoadingSpinner,
  useLayoutState,
} from 'components/shared/Layout';
import useHandleResponse from 'utils/useHandleResponse';

const useSendRequest = (props) => {
  // params
  const {
    url,
    query,
    body = {}, // body: to send any data that doesn't change to the hook
    method,
    beforeSuccessCallback,
    beforeErrorCallback,
    afterSuccessCallback,
    afterErrorCallback,
    overwriteSuccess,
    overwriteError,
    loader = true,
  } = props;

  let requestFunction = null;

  // state
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const { layoutState } = useLayoutState();
  const { handleError } = useHandleResponse();
  const [, setLoading] = useLayoutLoadingSpinner();

  const sendRequest = (extraBody = {}, extraQuery = {}) => {
    // extraBody: to send any data to the function direct not the hook, send data that can be changed to the function (e.g: signature error example)
    const params = [url, {
      authenticity_token: body.authenticityToken ? layoutState.authenticity_token : null,
      ...body,
      ...extraBody,
    }, {
      params: {
        ...query,
        ...extraQuery,
      },
    }];
    loader && setLoading(true);
    switch (method) {
      case 'POST': // takes 3 arguments in order [url, body, configuration: {header: ..., params: ...}]
        requestFunction = axios.post;
        break;
      case 'DELETE': // takes 2 arguments in order [url, configuration: {header: ..., params: (authenticty token, query, etc...)}]
        requestFunction = axios.delete;
        params.splice(1, 1);
        params[1].params.authenticity_token = body.authenticityToken ? layoutState.authenticity_token : null;
        break;
      case 'PUT':
        requestFunction = axios.put;
        break;
      case 'PATCH':
        requestFunction = axios.patch;
        break;
      default:
        requestFunction = axios.get;
        params.splice(1, 1);
        params[1].params.authenticity_token = body.authenticityToken ? layoutState.authenticity_token : null;
        break;
    }
    requestFunction(...params)
      .then((res) => {
        loader && setLoading(false);
        if (overwriteSuccess) {
          overwriteSuccess(res);
          return;
        }
        if (beforeSuccessCallback) {
          beforeSuccessCallback(res);
        }
        setResponse(res);
        if (afterSuccessCallback) {
          afterSuccessCallback(res);
        }
      })
      .catch((err) => {
        loader && setLoading(false);
        setError(err);
        if (overwriteError) {
          overwriteError(err);
          return;
        }
        if (beforeErrorCallback) {
          beforeErrorCallback(err);
          if (err.response.status === 406) {
            return;
          }
        }
        handleError(err);
        if (afterErrorCallback) {
          afterErrorCallback(err);
        }
      });
  };
  return {
    sendRequest, response, error,
  };
};
export default useSendRequest;
