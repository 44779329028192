import React, { memo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DestinationCard from 'common/components/DestinationCard';

const useStyles = makeStyles((theme) => ({
  itemClass: {
    padding: theme.spacing(0, 0, 0, 2),
  },
  sliderWrap: {
    marginLeft: theme.spacing(-2),
    position: 'relative',
    '& .react-multi-carousel-list': {
      position: 'unset',
    },
    '& .react-multiple-carousel__arrow--right': {
      right: theme.spacing(-3),
      background: theme.palette.secondary.dark,
      '&::before': {
        fontSize: theme.spacing(2),
      },
    },
    '& .react-multiple-carousel__arrow--left': {
      left: theme.spacing(-1.5),
      background: theme.palette.secondary.dark,
      '&::before': {
        fontSize: theme.spacing(2),
      },
    },
  },
}));

const FeaturedBikeDestinations = ({
  destinationsArr, setIsMoving, deviceType, ssrRender,
}) => {
  const classes = useStyles();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1199, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 600 },
      items: 2,
      partialVisibilityGutter: 60,
    },
    mobile: {
      breakpoint: { max: 600, min: 421 },
      items: 2,
      partialVisibilityGutter: 40,
    },
    sm: {
      breakpoint: { max: 420, min: 326 },
      items: 2,
      partialVisibilityGutter: 20,
    },
    xs: {
      breakpoint: { max: 325, min: 0 },
      items: 1,
      partialVisibilityGutter: 120,
    },
  };

  return (
    <>
      <Box className={classes.sliderWrap}>
        <Carousel
          swipeable
          draggable={false}
          ssr={ssrRender} // means to render carousel on server-side.
          // infinite
          keyBoardControl
          containerClass="carousel-container"
          removeArrowOnDeviceType={['xs', 'sm', 'tablet', 'mobile']}
          itemClass={classes.itemClass}
          responsive={responsive}
          partialVisible
          additionalTransfrom={-2}
          beforeChange={() => setIsMoving(true)}
          afterChange={() => setIsMoving(false)}
          minimumTouchDrag={20}
          customTransition="transform 200ms ease-in"
          deviceType={deviceType}
        >
          {destinationsArr
            && destinationsArr.map((destination) => (
              <DestinationCard destination={destination} key={destination.url} />
            ))}
        </Carousel>
      </Box>
    </>
  );
};

export default memo(FeaturedBikeDestinations);
