import React from 'react';
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  Button,
} from '@material-ui/core';
import { Router } from 'config/i18n';
import Image from 'next/image';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  Container: {
    backgroundColor: theme.palette.grey[700],
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      borderRadius: 15,
    },
  },
  contentGrid: {
    marginTop: theme.spacing(4.25),
    marginBottom: theme.spacing(8),
    textAlign: 'center',
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(3.5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(6.25),
      paddingLeft: theme.spacing(4.5),
      paddingRight: theme.spacing(4.5),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(12.5),
      textAlign: 'left',
      paddingLeft: theme.spacing(5.25),
      paddingRight: theme.spacing(5.25),
    },
  },
  mainTitle: {
    fontSize: theme.typography.pxToRem(28),
    marginBottom: theme.spacing(3),
    fontWeight: '500',
    color: theme.palette.common.white,
    lineHeight: theme.typography.pxToRem(40),
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(45),
      marginBottom: theme.spacing(4),
      lineHeight: theme.typography.pxToRem(62),
    },
  },
  info: {
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500',
    lineHeight: theme.typography.pxToRem(35),
    [theme.breakpoints.up('lg')]: {
      fontSize: theme.typography.pxToRem(24),
    },
  },
  imgBox: {
    overflow: 'hidden',
    position: 'relative',
    top: 0,
    right: 0,
    width: '100%',
    height: theme.spacing(34),
    [theme.breakpoints.only('md')]: {
      height: theme.spacing(70),
    },
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: 'auto',
    },
  },
  button: {
    marginTop: theme.spacing(4.25),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      marginTop: theme.spacing(5.5),
    },
  },
}));

const Advertise = ({
  title, info, button, imgUrl,
}) => {
  const classes = useStyles();

  const handleBtnClick = () => {
    Router.push(button.url.pathName, button.url.as);
  };

  return (
    <>
      <Container className={classes.mainContainer}>
        <Grid
          container
          className={classes.Container}
          alignItems="stretch"
        >

          <Grid item xs={12} lg={5}>
            <Box className={classes.contentGrid}>
              <Typography variant="h2" classes={{ root: classes.mainTitle }}>
                {title}
              </Typography>

              <Typography
                classes={{ root: classes.info }}
              >
                {info}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleBtnClick}
                classes={{ root: classes.button }}
              >
                {button.text}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Box className={classes.imgBox}>
              <Image
                src={imgUrl}
                alt={title}
                layout="fill"
                objectFit="cover"
                unoptimized
                placeholder="blur"
              />
            </Box>

          </Grid>

        </Grid>
      </Container>
    </>
  );
};

export default Advertise;
