import React from 'react';
import {
  makeStyles, Box, useTheme, Divider, Button, Hidden, useMediaQuery,
} from '@material-ui/core';
import SearchPickup from 'views/motorcycle_rental/components/widget/SearchPickup';
import HomeWidgetDatePicker from 'views/home/components/HomeWidgetDatePicker';
import SearchIcon from '@material-ui/icons/Search';
import { useLayoutLoadingSpinner } from 'components/shared/Layout';

const useStyles = makeStyles((theme) => ({
  widgetBox: {
    width: '60%',
    height: theme.spacing(9.375),
    display: 'flex',
    background: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    padding: 0,
    marginTop: theme.spacing(3),
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: `1px solid ${theme.palette.grey[100]}`,
    position: 'relative',
    [theme.breakpoints.only('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      padding: theme.spacing(0, 2),
      background: 'transparent',
      boxShadow: 'none',
      border: 'none',
      height: 'auto',
    },

  },
  widgetDivider: {
    height: '60%',
    margin: `auto ${theme.spacing(1)}`,
    backgroundColor: theme.palette.grey[100],
  },
  searchBtn: {
    minWidth: 'auto',
    width: theme.spacing(6.25),
    height: theme.spacing(6.25),
    position: 'absolute',
    right: theme.spacing(0.75),
    top: '50%',
    marginTop: theme.spacing(-3.125),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      marginTop: theme.spacing(2),
      top: 0,
      right: 0,
      '& svg': {
        marginLeft: theme.spacing(1),
        width: theme.spacing(2.5),
      },
    },
  },
}));

const HomeWidget = ({ i18n, widgetProps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [, setLoading] = useLayoutLoadingSpinner();

  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.widgetBox}>
      <SearchPickup
        data={{
          ...widgetProps.place,
          label: i18n('fe_geo_widget:place.where'),
          popperPaperStyle: {
            borderRadius: smallScreen ? theme.spacing(1) : theme.spacing(0, 0, 1, 1),
            paddingBottom: widgetProps.place.pickupLocations.length ? theme.spacing(1) : 0,
            marginTop: smallScreen ? theme.spacing(-1) : theme.spacing(-1.25),
          },
          variant: smallScreen ? 'outlined' : 'standard',
          ShowCurrentLocationButton: true,
          homeWidget: true,
        }}
      />
      <Hidden smDown>
        <Divider orientation="vertical" className={classes.widgetDivider} />
      </Hidden>
      <HomeWidgetDatePicker
        i18n={i18n}
        data={{
          ...widgetProps.time,
          tooltip: !widgetProps.place.selectedPickupPlace,
        }}
      />
      <Button
        color="primary"
        variant="contained"
        className={classes.searchBtn}
        onClick={() => {
          setLoading(true);
          widgetProps.search();
        }}
      >
        <Hidden mdUp>
          {i18n('fe_geo_widget:time.search')}
        </Hidden>
        <SearchIcon />
      </Button>
    </Box>
  );
};
export default HomeWidget;
