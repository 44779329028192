import React, { memo } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {
  Box, makeStyles, useTheme, useMediaQuery,
} from '@material-ui/core';
import BikeCard from 'common/components/bike_card/BikeCard';

const useStyles = makeStyles((theme) => ({
  itemClass: {
    padding: theme.spacing(0, 0, 0.5, 2),
  },
  dotsClass: {
    marginLeft: theme.spacing(2),
  },
  sliderWrap: {
    marginLeft: theme.spacing(-2),
    paddingBottom: theme.spacing(3.75),
    position: 'relative',
    '& .react-multi-carousel-list': {
      position: 'unset',
    },
    '& .react-multiple-carousel__arrow--right': {
      right: theme.spacing(-3),
      background: theme.palette.secondary.dark,
      '&::before': {
        fontSize: theme.spacing(2),
      },
    },
    '& .react-multiple-carousel__arrow--left': {
      left: theme.spacing(-1.5),
      background: theme.palette.secondary.dark,
      '&::before': {
        fontSize: theme.spacing(2),
      },
    },
  },
}));

const BikesCardsSlider = ({
  cardsArr, onBikeCardClick, setIsMoving, deviceType, ssrRender,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1200 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1199, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1023, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <Box className={classes.sliderWrap}>
      <Carousel
        swipeable
        draggable={false}
        ssr={ssrRender} // means to render carousel on server-side.
        // infinite
        showDots={!!smallScreen}
        renderDotsOutside
        keyBoardControl
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        itemClass={classes.itemClass}
        dotListClass={classes.dotsClass}
        responsive={responsive}
        additionalTransfrom={!smallScreen && -2}
        beforeChange={() => setIsMoving(true)}
        afterChange={() => setIsMoving(false)}
        minimumTouchDrag={20}
        customTransition="transform 200ms ease-in"
        deviceType={deviceType}
      >
        {cardsArr.map((bike) => (
          <BikeCard bike={bike} key={bike.id} onBikeCardClick={onBikeCardClick} />
        ))}
      </Carousel>
    </Box>
  );
};
export default memo(BikesCardsSlider);
